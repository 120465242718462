import React, { useMemo, useState } from 'react';
import { Dropdown, Spin } from 'antd';
import {
  AWAssetListItemDto,
  ListAssetsToReviewInBatchResultDto
} from '@api/Api';
import { useAuth } from '@hooks/useAuth';
import { useAWListAssetsToReviewInBatch } from '@hooks/queries/approval';
import { ReactComponent as SortArrowSvg } from '@assets/icons/sort-arrow.svg';
import { ReactComponent as DownArrowSvg } from '@assets/icons/arrow-down.svg';
import LottieComponent from '@components/Lottie';
import ApprovalAssetCard from '@pages/ApprovalReviewer/ApprovalAssetsList/components/ApprovalAssetCard';
import ApprovalBulkActions from '@pages/ApprovalReviewer/ApprovalAssetsList/components/ApprovalBulkActions';
import { sortedAWListAssetsToReviewInBatch } from '@components/Modals/ApprovalModal/utils';

import './ApprovalAssetsList.less';

const ApprovalAssetBreadcrumbs = React.lazy(
  () =>
    import(
      '@pages/ApprovalReviewer/ApprovalAssetsList/components/ApprovalAssetBreadcrumbs'
    )
);

type ApprovalAssetsListProps = {
  initialData?: ListAssetsToReviewInBatchResultDto;
  enabled?: boolean;
  batchId: string;
  password: string | null;
};

export default function ApprovalAssetsList({
  initialData,
  enabled = true,
  batchId,
  password
}: ApprovalAssetsListProps) {
  const { user } = useAuth();
  const [selectedAssets, setSelectedAssets] = useState<AWAssetListItemDto[]>(
    []
  );
  const [sort, setSort] = useState<string>('needReview');
  const [selectedFilter, setSelectedFilter] = useState<string>('allAssets');

  const { data: approvalReviewInBatch, isLoading } =
    useAWListAssetsToReviewInBatch({
      initialData,
      enabled,
      params: {
        batchId: batchId!,
        password
      }
    });

  const sortedApprovalListAssets = useMemo(() => {
    if (!approvalReviewInBatch?.assets?.length) return [];
    let _approvalListAssets = approvalReviewInBatch?.assets;

    if (selectedFilter !== 'allAssets' && !!selectedFilter) {
      _approvalListAssets = _approvalListAssets.filter((item) => {
        if (selectedFilter === 'waiting_for_review')
          return (
            !item.decision &&
            item.canSubmitDecision &&
            !item.isFinished &&
            (!item.isPaused || !item.isFutureReview)
          );
        return item.decision === selectedFilter;
      });
    }

    return sortedAWListAssetsToReviewInBatch(_approvalListAssets, sort);
  }, [approvalReviewInBatch, selectedFilter, sort]);

  const onChangeSelectedAssets = (asset: AWAssetListItemDto) => {
    setSelectedAssets((prevSelectedAssets) => {
      const existAsset = prevSelectedAssets.find(
        (item) => item.id === asset.id
      );
      if (existAsset)
        return prevSelectedAssets.filter((item) => item.id !== asset.id);
      return [...prevSelectedAssets, asset];
    });
  };

  const assetsFiltersItems = useMemo(() => {
    return [
      { label: 'All assets', key: 'allAssets' },
      {
        label: (
          <>
            <span
              className="help_dropdown__dot"
              style={{ background: '#F5DAE8' }}
            />{' '}
            Waiting for review
          </>
        ),
        key: 'waiting_for_review'
      },
      {
        label: (
          <>
            <span
              className="help_dropdown__dot"
              style={{ background: '#8DD2B9' }}
            />{' '}
            Approved
          </>
        ),
        key: 'approved'
      },
      {
        label: (
          <>
            <span
              className="help_dropdown__dot"
              style={{ background: '#BFC5F4' }}
            />{' '}
            Approved with feedback
          </>
        ),
        key: 'approved_with_feedback'
      },
      {
        label: (
          <>
            <span
              className="help_dropdown__dot"
              style={{ background: '#F5C8BE' }}
            />{' '}
            Changes required
          </>
        ),
        key: 'changes_required'
      }
    ];
  }, []);

  const selectedFilterLabel = useMemo(
    () =>
      assetsFiltersItems.find((item) => item.key === selectedFilter)?.label ||
      'All assets',
    [selectedFilter]
  );

  if (isLoading)
    return (
      <div
        className="approval-assets-list"
        style={{
          height: 'calc(100dvh - 300px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <>
      {user?.type === 'internal' &&
        approvalReviewInBatch?.campaignId &&
        approvalReviewInBatch.campaignName && (
          <ApprovalAssetBreadcrumbs
            campaignId={approvalReviewInBatch?.campaignId}
            campaignName={approvalReviewInBatch?.campaignName}
          />
        )}
      <div className="approval-assets-list">
        <div className="approval-assets-list_head">
          <div className="approval-assets-list_title">
            {approvalReviewInBatch?.campaignName || 'Media list'}
          </div>
          <div className="approval-assets-list_actions">
            <Dropdown
              menu={{
                selectable: true,
                selectedKeys: [sort],
                onClick: ({ key }: { key: string }) => setSort(key),
                items: [
                  { label: 'Need to review', key: 'needReview' },
                  { label: 'Already completed review', key: 'completedReview' }
                ]
              }}
              prefixCls="approval-assets-list_dropdown"
              placement="bottomRight"
              trigger={['click']}
            >
              <button
                type="button"
                className="approval-assets-list_dropdown-btn"
              >
                Sort
                <SortArrowSvg />
              </button>
            </Dropdown>
            <Dropdown
              menu={{
                selectable: true,
                selectedKeys: [selectedFilter],
                onClick: ({ key }: { key: string }) => setSelectedFilter(key),
                items: assetsFiltersItems
              }}
              prefixCls="approval-assets-list_dropdown"
              overlayClassName="media_viewer_mobile-overlay"
              placement="bottomRight"
              trigger={['click']}
            >
              <button
                type="button"
                className="approval-assets-list_dropdown-btn"
              >
                {selectedFilterLabel}
                <DownArrowSvg />
              </button>
            </Dropdown>
          </div>
        </div>
        {!sortedApprovalListAssets.length && (
          <div className="approval-assets-list_empty">
            <LottieComponent
              className="approval-assets-list_empty-icon"
              view="addMediaInFolder"
            />
            <strong className="main-body-text main-body-text--bold">
              No assets found
            </strong>
            <p className="main-body-text">try selecting other options</p>
          </div>
        )}
        {!!sortedApprovalListAssets.length && (
          <div className="approval-assets-list_content">
            {(sortedApprovalListAssets || []).map((item) => {
              const isSelected = !!selectedAssets.find(
                (asset) => asset.id === item.id
              );
              return (
                <div key={item.id} className="approval-assets-list_item">
                  <ApprovalAssetCard
                    asset={item}
                    isSelected={isSelected}
                    onSelect={onChangeSelectedAssets}
                  />
                </div>
              );
            })}
            <ApprovalBulkActions
              assets={selectedAssets}
              password={password}
              onClose={() => setSelectedAssets([])}
            />
          </div>
        )}
      </div>
    </>
  );
}
